// You can add global styles to this file, and also import other style files
@import './sonomat-theme';

// For Angular material.
html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
app-root {
    margin: 0;
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Provides multi-browser support for disabling the blue selection box one mobile browsers.
// This class should only be used if replacing the selection indicator with a mat ripple or
// other such effect.
// https://stackoverflow.com/questions/21003535/anyway-to-prevent-the-blue-highlighting-of-elements-in-chrome-when-clicking-quic
.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noSelect:focus {
    outline: none !important;
}

// Define for responsive hidden blocks
.responsive-hidden {
    display: none;
}
